import React from 'react'
import Layout from '../components/Layout/Layout'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../components/Button/Button';

export default function () {

  const data = useStaticQuery(graphql`
  {
    file(name: {eq: "contact-background"}){
      childImageSharp{
        fluid(quality: 100, maxWidth: 1920){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

  `)


  return (
    <Layout>
        <div>
        <h2 style={{ textAlign: "center", fontWeight: "normal" }}>Wir freuen uns auf Ihre Nachricht!</h2>
      </div>
      <div style={{ padding: "20px" }}>
        <Button onClick={() => window.open("http://goo.gl/forms/W3edrWtK9RrvKJ5Z2", "_blank")}>Kontaktformular</Button>
      </div>




      <section className="contactGrid">
        <div className="contactDetails">
          <div><Icon size="lg" icon={faEnvelope} /><h2>post@spreeplan.de</h2></div>
          <div><Icon size="lg" icon={faPhone} /><h2>+49 (030) 33772450</h2></div>
        </div>

        <div>
          <h2 style={{fontWeight: "bold"}}>Anschrift</h2>
          <h2>
            Spreeplan Projekt UG (haftungsbeschränkt)
            Reichsstraße 108
            14052 Berlin
          </h2>
        </div>

        <div>
          <h2>Anfahrt:</h2>
        </div>

        <div>
          <h2>
            U-Bahn / Bus Theodor-Heuss-Platz
          </h2>
        </div>
        
      </section>

    </Layout>
  )
}

